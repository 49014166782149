let resumeData = {
  "imagebaseurl": "https://rbhatia46.github.io/",
  "name": "Kyle Kluever",
  "role": "Cloud architect/engineer",
  "linkedinId": "https://www.linkedin.com/in/kyle-kluever-24971a32/",
  "roleDescription": "I deliver AWS solutions at enterprise scale using design, engineering and technology skills with a strong focus on infrastructure as code and designing security in from the start.",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/kyle-kluever-24971a32/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/kylekluever",
      "className": "fa fa-github"
    }
  ],
  "aboutme": "My background is in enterprise application development as a full stack java engineer. Over the last few years I moved into an application architecture role. Currently I'm part of our organization's cloud engineering team responsible for a full scale migration from on-premise data centers to AWS.",
  "address": "Hugo, MN",
  "website": "https://www.kylekluever.com",
  "education": [
    {
      "UniversityName": "St. John's University - Collegeville, MN",
      "specialization": "Computer Science",
      "MonthOfPassing": "May",
      "YearOfPassing": "2006",
      "Achievements": "Bachelor of Arts in Computer Science"
    }
  ],
  "work": [
    {
      "CompanyName": "Securian Financial",
      "specialization": "Cloud Engineering Team - Architecture Senior Consultant",
      "Start": "September 2019",
      "Left": "Present",
      "Achievements": ""
    },
    {
      "CompanyName": "Securian Financial",
      "specialization": "Institutional Market Technology - Application Development Consultant",
      "Start": "March 2017",
      "Left": "September 2019",
      "Achievements": ""
    },
    {
      "CompanyName": "Securian Financial",
      "specialization": "Institutional Market Technology - Application Engineer",
      "Start": "September 2013",
      "Left": "March 2017",
      "Achievements": ""
    }
  ],
  "skills": [
    {
      "skillname": "Cloud Architecture"
    },
    {
      "skillname": "Java"
    },
    {
      "skillname": "CI/CD Pipelines"
    },
    {
      "skillname": "Python"
    }
  ],
  "certifications": [
    {
      "company": "AWS",
      "name": "Certified Solutions Architect - Professional",
      "image": "images/aws-csap.png",
      "imageStyle": {
        "height": "150px",
        "width": "150px"
      },
      "url": "https://www.credly.com/badges/d32fa710-e48b-4284-a592-8e60735da22d"
    },
    {
      "company": "AWS",
      "name": "Certified Solutions Architect - Associate",
      "image": "images/aws-csaa.png",
      "imageStyle": {
        "height": "150px",
        "width": "150px"
      },
      "url": "https://www.credly.com/badges/11884be1-85b0-481f-98ed-1a56ec75fc23"
    },
    {
      "company": "AWS",
      "name": "Certified Security - Specialty",
      "image": "images/aws-css.png",
      "imageStyle": {
        "height": "150px",
        "width": "150px"
      },
      "url": "https://www.credly.com/badges/106a18bd-527d-43fa-a289-fc4039941857"
    }
  ]
}

export default resumeData